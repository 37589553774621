import React, { useEffect, useState, useRef } from 'react';
import classnames from 'classnames';
import Link from 'next/link';
import FacebookIcon from '@/assets/svgs/facebook.svg';
import InstagramIcon from '@/assets/svgs/instagram-shape.svg';
import LanguageSwitcher from '../LanguageSwitcher/LanguageSwitcher';
import styles from './Menu.module.scss';
import routes from '@/data/routes';
import { gsap } from 'gsap';
import { setNoiseMask, setIsMenuOpen, setCursorState, setCursorLocked, useAppDispatch, useAppSelector } from '@/redux';
// import debounce from 'lodash.debounce';
import { device } from '@jam3/detect';
import PlantsBg from '@/assets/svgs/plant.svg';

const Fragment = React.Fragment;

export type Props = {
  routerRoute: string;
  activeLang: string;
  plantBar: HTMLElement | null;
};

function Menu({ routerRoute, activeLang, plantBar }: Props) {
  const [actualRoute, setActualRoute] = useState(routerRoute);
  const mainHeader = useRef<HTMLElement | null>(null);
  const navLinks: (HTMLAnchorElement | null)[] = [];
  const TimeLineIn = useRef<GSAPTimeline | null>(null);
  const TimeLineOut = useRef<GSAPTimeline | null>(null);
  const dispatch = useAppDispatch();
  const isMenuOpen = useAppSelector((state) => state.isMenuOpen);

  useEffect(() => {
    mainHeader.current = document.querySelector('header.Header');
  }, []);

  useEffect(() => {
    if (actualRoute !== routerRoute && isMenuOpen) {
      dispatch(setIsMenuOpen(false));

      if (routerRoute === '/') {
        dispatch(setNoiseMask({ state: 'render-out', theme: 'light' }));
      }
    }
    setActualRoute(routerRoute);
  }, [routerRoute, isMenuOpen, actualRoute]);

  useEffect(() => {
    if (isMenuOpen) {
      dispatch(setIsMenuOpen(false));
    }
  }, [activeLang]);

  useEffect(() => {
    if (isMenuOpen) {
      if (TimeLineOut.current && TimeLineOut.current.isActive()) {
        TimeLineOut.current.kill();
      }
      //   dispatch(setNoiseMask({ state: 'render-in', theme: 'dark' }));
      //   dispatch(setNoiseMask({ state: 'in', theme: 'dark' }));
      mainHeader.current?.classList.add('Header--mobileOpen');
      plantBar?.classList.add('plantBar--hidden');

      TimeLineIn.current = gsap
        .timeline({ defaults: { duration: 1.2, ease: 'expo' } })
        .set(navLinks, {
          y: '150%',
          rotate: 15
        })
        .set('.Menu__bg', {
          opacity: 1
        })
        .set('.Menu__bgPlant-wrap', {
          opacity: 0,
          rotate: 2,
          scaleY: 1.2,
          y: '10%'
        })
        .to(navLinks, {
          y: '0%',
          rotate: 0,
          stagger: 0.03
        })
        .to(
          '.Menu__bgPlantTop-wrap',
          {
            opacity: 1,
            rotate: 0,
            scaleY: '1',
            y: '0'
          },
          0.2
        )
        .to(
          '.Menu__bgPlant-wrap',
          {
            opacity: 1,
            rotate: 0,
            scaleY: '1',
            y: '0'
          },
          0.2
        );
    } else {
      if (TimeLineIn.current && TimeLineIn.current.isActive()) {
        TimeLineIn.current.kill();
      }

      TimeLineOut.current = gsap
        .timeline({
          defaults: { duration: 0.7, ease: 'power2' }
        })
        .to(navLinks, {
          y: '-150%',
          rotate: -5,
          stagger: 0.03
          // onComplete: () => {
          //   dispatch(setNoiseMask({ state: 'render-out', theme: 'dark' }));
          //   dispatch(setNoiseMask({ state: 'out', theme: 'dark' }));
          // }
        })
        .to(
          '.Menu__bgPlantTop-wrap',
          {
            opacity: 0,
            rotate: 2,
            scaleY: 1.06,
            y: '-7%'
          },
          0.1
        )
        .to(
          '.Menu__bgPlant-wrap',
          {
            opacity: 0,
            rotate: 2,
            scaleY: 1.06,
            y: '7%'
          },
          0.1
        )
        .to(
          '.Menu__bg',
          {
            opacity: 0,
            clearProps: 'all',
            onComplete: () => plantBar?.classList.remove('plantBar--hidden')
          },
          0.6
        );

      mainHeader.current?.classList.remove('Header--mobileOpen');
    }
  }, [isMenuOpen]);

  const handleCursorLocked = (event: any) => {
    const bounds = event.target.getBoundingClientRect();
    device.desktop &&
      dispatch(setCursorLocked({ locked: true, x: bounds.x + bounds.width / 2, y: bounds.y + bounds.height / 2 }));
  };

  return (
    <Fragment>
      <div className={classnames(styles.Menu, isMenuOpen && styles.MenuOpen)}>
        <div className={classnames(styles.Menu__bg, 'Menu__bg')}></div>
        <div className={classnames(styles.Menu__bgPlantTop, 'Menu__bgPlantTop')}>
          <div className="Menu__bgPlantTop-wrap">
            <PlantsBg />
          </div>
        </div>
        <div className={classnames(styles.Menu__bgPlant, 'Menu__bgPlant')}>
          <div className="Menu__bgPlant-wrap">
            <PlantsBg />
          </div>
        </div>

        <div className={styles.Menu__inner}>
          <nav className={styles.nav}>
            <ul className={styles.routes}>
              {Object.values(routes).map(({ path, title_en, title_it }) => {
                if (path !== '/contatti' && path !== '/news') {
                  return (
                    <li
                      key={path}
                      className={classnames(path === routerRoute ? styles.NavLinkActive : null, 'NavLinks')}
                    >
                      <Link href={path} prefetch={false}>
                        <a
                          onMouseEnter={() => {
                            device.desktop && dispatch(setCursorState('hover'));
                          }}
                          onMouseLeave={() => {
                            device.desktop && dispatch(setCursorState('default'));
                          }}
                          ref={(el) => navLinks.push(el)}
                        >
                          {activeLang === 'en' ? title_en : title_it}
                        </a>
                      </Link>
                    </li>
                  );
                }
              })}
            </ul>
          </nav>

          <div className={styles.sidebar}>
            <div
              className={classnames(
                routerRoute === '/contatti/' ? styles.sidebar__linkActive : null,
                styles.sidebar__link,
                'NavLinks'
              )}
            >
              <Link href="/contatti/" prefetch={false}>
                <a
                  onMouseEnter={() => {
                    device.desktop && dispatch(setCursorState('hover'));
                  }}
                  onMouseLeave={() => {
                    device.desktop && dispatch(setCursorState('default'));
                  }}
                  ref={(el) => navLinks.push(el)}
                >
                  {activeLang === 'en' ? 'Contacts' : 'Contatti'}
                </a>
              </Link>
            </div>

            <div className={styles.social}>
              <a
                onMouseEnter={() => {
                  device.desktop && dispatch(setCursorState('hover'));
                }}
                onMouseLeave={() => {
                  device.desktop && dispatch(setCursorState('default'));
                }}
                ref={(el) => navLinks.push(el)}
                target="blank"
                href="https://www.facebook.com/ioleliquoreartigianale"
              >
                <FacebookIcon />
              </a>
              <a
                onMouseEnter={() => {
                  device.desktop && dispatch(setCursorState('hover'));
                }}
                onMouseLeave={() => {
                  device.desktop && dispatch(setCursorState('default'));
                }}
                ref={(el) => navLinks.push(el)}
                target="blank"
                href="https://www.instagram.com/i.o.l.e.liquoreartigianale"
              >
                <InstagramIcon />
              </a>
            </div>
            <LanguageSwitcher menuOpen={isMenuOpen} />
          </div>
        </div>
      </div>

      <div
        className={styles.BurgerWrapper}
        onMouseEnter={handleCursorLocked}
        onMouseLeave={() => {
          device.desktop && dispatch(setCursorLocked({ locked: false, x: 0, y: 0 }));
        }}
        onClick={() => {
          dispatch(setIsMenuOpen(!isMenuOpen));
        }}
      >
        <div className={classnames(styles.Burger, isMenuOpen && styles.BurgerClose, 'Burger')}>
          <div className={styles.Bar}></div>
          <div className={styles.Bar}></div>
          <div className={styles.Bar}></div>
        </div>
      </div>
    </Fragment>
  );
}

export default Menu;
