export type Route = {
  readonly path: string;
  readonly title_it: string;
  readonly title_en: string;
};

export interface Routes {
  readonly Home: Route;
  readonly Tonico: Route;
  readonly Mixology: Route;
  readonly News: Route;
  readonly About: Route;
  readonly Contatti: Route;
}

const routes: Routes = {
  Home: {
    path: '/',
    title_it: 'Home',
    title_en: 'Home'
  },
  Tonico: {
    path: '/tonico',
    title_it: 'Il tonico',
    title_en: 'The liquor'
  },
  About: {
    path: '/about',
    title_it: 'Chi siamo',
    title_en: 'About us'
  },
  Mixology: {
    path: '/mixology',
    title_it: 'Mixology',
    title_en: 'Mixology'
  },
  News: {
    path: '/news',
    title_it: 'News',
    title_en: 'News'
  },
  Contatti: {
    path: '/contatti',
    title_it: 'Contatti',
    title_en: 'Contacts'
  }
};

export default routes;
