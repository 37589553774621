import { useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { AppProps } from 'next/app';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { LocomotiveScrollProvider } from '@/contexts/react-locomotive-scroll';
import { CookiesProvider } from 'react-cookie';
import { AnimatePresence } from 'framer-motion';
import { device, browser } from '@jam3/detect';
import classnames from 'classnames';
import { useRouter } from 'next/router';

import 'normalize.css';

import '@/styles/main.scss';

import Layout from '@/components/Layout/Layout';

import { store } from '@/redux';
// import '@/utils/why-did-you-render';

const isBrowser = typeof window !== 'undefined';

if (isBrowser) {
  require('default-passive-events');
  require('focus-visible');
}

// This default export is required in a new `pages/_app.js` file.
function App({ Component, pageProps }: AppProps) {
  const { isUnsupported, ...componentProps } = pageProps;
  const siteWrapperRef = useRef<HTMLDivElement>(null);
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const router = useRouter();

  useEffect(() => {
    if (isBrowser) {
      if (process.env.NODE_ENV !== 'production' && window.location.href.indexOf('?nostat') === -1) {
        require('@jam3/stats')();
      }

      document.body.className = `${document.body.className} ${classnames(device.type, browser.name, {
        'touch-device': device.touch
      })}`.trim();
    }
  }, []);

  if (isUnsupported) {
    return <Component {...componentProps} />;
  }

  return (
    <GoogleReCaptchaProvider
      useRecaptchaNet
      reCaptchaKey={
        process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY
          ? process.env.NEXT_PUBLIC_GOOGLE_RECAPTCHA_SITE_KEY
          : '6Le3AQ4dAAAAAKnyTZz2O1CbJN9Z35Ce7KNQSgK7'
      }
      scriptProps={{
        async: true,
        defer: true,
        appendTo: 'body'
      }}
    >
      <CookiesProvider>
        <Provider store={store}>
          <Layout>
            <LocomotiveScrollProvider
              watch={[]}
              location={router.asPath}
              siteWrapperRef={siteWrapperRef}
              scrollContainerRef={scrollContainerRef}
            >
              <main className="MainContent" ref={siteWrapperRef}>
                <div data-scroll-container ref={scrollContainerRef}>
                  <AnimatePresence exitBeforeEnter>
                    <Component {...componentProps} key={router.route} />
                  </AnimatePresence>
                </div>
              </main>
            </LocomotiveScrollProvider>
          </Layout>
        </Provider>
      </CookiesProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
