import React from 'react';
import classnames from 'classnames';
import { useRouter } from 'next/router';
import { setCursorState, useAppDispatch } from '@/redux';
import { device } from '@jam3/detect';

import styles from './LanguageSwitcher.module.scss';

function LanguageSwitcher({ menuOpen }: { menuOpen: boolean }) {
  const dispatch = useAppDispatch();
  const router = useRouter();

  return (
    <div className={classnames(styles.Switcher, menuOpen && styles.SwitcherVisible, 'Switcher')}>
      <button
        className={styles.button}
        onMouseEnter={() => {
          device.desktop && dispatch(setCursorState('hover'));
        }}
        onMouseLeave={() => {
          device.desktop && dispatch(setCursorState('default'));
        }}
        onClick={() => {
          if (router.locale === 'en') {
            router.push(
              router.pathname === '/news/[slug]'
                ? `/news/${router.query.slug}`
                : router.pathname === '/mixology/[slug]'
                ? `/mixology/${router.query.slug}`
                : router.route,
              undefined,
              {
                locale: 'it'
              }
            );
          } else {
            router.push(
              router.pathname === '/news/[slug]'
                ? `/news/${router.query.slug}`
                : router.pathname === '/mixology/[slug]'
                ? `/mixology/${router.query.slug}`
                : router.route,
              undefined,
              {
                locale: 'en'
              }
            );
          }
        }}
      >
        {router.locale === 'en' ? 'IT' : 'EN'}
      </button>
      <span className={styles.disabled}>{router.locale === 'en' ? 'EN' : 'IT'}</span>
    </div>
  );
}

export default LanguageSwitcher;
