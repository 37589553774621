import { memo, Fragment } from 'react';
import NextHead from 'next/head';

if (typeof window !== 'undefined') window.dataLayer = window.dataLayer || [];

type Props = {
  consent: string | undefined;
};

/**
 * Dispatch an event with GTM
 *
 * @param {boolean} [action=false] - Action name
 * @param {any} [payload={}] - Action data
 */
// export function gtmEvent(action = false, payload = {}): void {
//   if (typeof window !== 'undefined' && action) {
//     window.dataLayer.push({
//       event: action,
//       payload
//     });
//   }
// }

/**
 * GTM code snippet in head
 */
// const GtmScript = () => {
//   return (
//     // TODO: next/head is not currently not accepting next/script component.
//     //       when it is fixed, use next/script
//     // eslint-disable-next-line
//     <script
//       id="gtm-container"
//       /* <!-- Google Tag Manager --> */
//       // eslint-disable-next-line
//       dangerouslySetInnerHTML={{
//         __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
//           new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
//           j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
//           'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
//           })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTM_ID}');`
//       }}
//     />
//   );
// };

/**
 * Facebook Pixel Code
 */
const FacebookPixel = () => {
  return (
    // TODO: next/head is not currently not accepting next/script component.
    //       when it is fixed, use next/script
    <Fragment>
      <script
        id="facebook-pixel"
        dangerouslySetInnerHTML={{
          __html: `(function () {!function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}');
        fbq('track', 'PageView');})()`
        }}
      />
      <noscript
        dangerouslySetInnerHTML={{
          __html: `
                  <img
                  height="1"
                  width="1"
                  style="display:none"
                  src="https://www.facebook.com/tr?id=${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}&ev=PageView&noscript=1"
                />`
        }}
      />
    </Fragment>
  );
};

function Analytics({ consent }: Props) {
  return <NextHead>{consent === 'true' && FacebookPixel()}</NextHead>;
}

export default memo(Analytics);
