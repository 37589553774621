import { memo, useEffect, PropsWithChildren, useState } from 'react';
import { LazyMotion, domAnimation } from 'framer-motion';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import Header from '@/components/Header/Header';
import AgeGate from '@/components/AgeGate/AgeGate';
import Cursor from '@/components/Cursor/Cursor';
import Analytics from '@/utils/analytics';
import { useCookies } from 'react-cookie';
import {
  setNoiseMask,
  setCursorLocked,
  setCursorState,
  setIsWebpSupported,
  useAppDispatch,
  useAppSelector
} from '@/redux';
import { checkWebpSupport } from '@/utils/basic-functions';
import { device } from '@jam3/detect';
import grain from '@/assets/images/grain.png';
import plantLight from '@/assets/images/plant-white.png';
import plantDark from '@/assets/images/plant-dark.png';
import debounce from 'lodash.debounce';

const Mask = dynamic(() => import('../Mask/Mask'));
// const AppAdmin = dynamic(() => import('@/components/AppAdmin/AppAdmin'), { ssr: false });

export type Props = PropsWithChildren<{}>;

function Layout({ children }: Props) {
  const activeLang = useAppSelector((state) => state.language);
  const ageGate = useAppSelector((state) => state.ageGate);
  const isMenuOpen = useAppSelector((state) => state.isMenuOpen);
  const dispatch = useAppDispatch();
  const router = useRouter();
  // @ts-ignore
  const [cookies, setCookie] = useCookies(['isover18']);
  // @ts-ignore
  const [cookieConsentValue, setCookieConsentValue] = useState<string | undefined>(getCookieConsentValue());
  const resizeDebounced = debounce(() => {
    window.dispatchEvent(new Event('resize'));
  }, 1800);

  useEffect(() => {
    checkWebpSupport('lossy', (isSupported) => dispatch(setIsWebpSupported(isSupported)));
    // router.events.on('routeChangeStart', routeChangeStart);
    resizeDebounced();
  }, [dispatch]);

  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);

  const handleRouteChange = (url: string) => {
    if (device.desktop) {
      dispatch(setCursorState('default'));
      dispatch(setCursorLocked({ locked: false, x: 0, y: 0 }));
    }

    if (!isMenuOpen) {
      if (url === '/' || url === '/en/') {
        const animateMaskOut = debounce(() => {
          dispatch(setNoiseMask({ state: 'out', theme: 'light' }));
        }, 900);
        animateMaskOut();
      }
    }

    resizeDebounced();

    if (window.fbq) {
      window.fbq('track', 'PageView');
    }
  };

  return (
    <LazyMotion features={domAnimation}>
      {process.env.NODE_ENV === 'production' && <Analytics consent={cookieConsentValue} />}
      <Header />

      {children}

      {typeof window !== 'undefined' && device.desktop && <Mask />}

      {ageGate && <AgeGate />}

      {device.desktop && <Cursor />}

      <CookieConsent
        disableStyles={true}
        onAccept={() => setCookieConsentValue('true')}
        buttonText={activeLang === 'en' ? 'I understand' : 'Ho capito'}
        debug={false}
      >
        {activeLang === 'en'
          ? 'Our website uses only technical cookies strictly necessary for navigation'
          : 'Questo sito utilizza esclusivamente cookie tecnici strettamente necessari alla navigazione'}
      </CookieConsent>
      {device.desktop && (
        <div className="plantBar">
          <div className="plantBar__inner" style={{ backgroundImage: `url(${plantDark})` }}>
            <div
              className="plantBar__inner-bg plantBar__inner-bg--light"
              style={{ backgroundImage: `url(${plantDark})` }}
            ></div>
            <div
              className="plantBar__inner-bg plantBar__inner-bg--dark"
              style={{ backgroundImage: `url(${plantLight})` }}
            ></div>
          </div>
        </div>
      )}

      <div className="grain" style={{ backgroundImage: `url(${grain})` }}></div>

      {/* {process.env.NEXT_PUBLIC_ENVIRONMENT !== 'production' && <AppAdmin />} */}
    </LazyMotion>
  );
}

export default memo(Layout);
