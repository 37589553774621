import React, { useEffect, useRef } from 'react';
import { useAppSelector, setNoiseMask, setCursorState, useAppDispatch, setLanguage } from '@/redux';
import { device } from '@jam3/detect';
import classnames from 'classnames';
import Link from 'next/link';
import { useRouter } from 'next/router';
import Menu from './Menu/Menu';
import Logo from '@/assets/svgs/logo.svg';
import PlantCircle from '@/assets/svgs/plant-circle.svg';
import debounce from 'lodash.debounce';

import styles from './Header.module.scss';

function Header() {
  const dispatch = useAppDispatch();
  const activeLang = useAppSelector((state) => state.language);
  const router = useRouter();
  const headerRef = useRef<HTMLElement>(null);
  const plantBar = useRef<HTMLElement | null>(null);

  useEffect(() => {
    plantBar.current = document.querySelector('.plantBar') as HTMLElement;

    dispatch(setLanguage(router.locale as string));
    headerRef.current?.classList.remove('Header--light', 'Header--dark');
    plantBar.current?.classList.remove('plantBar--light', 'plantBar--dark');

    if (router.asPath !== '/') {
      headerRef.current?.classList.add('Header--light');
      plantBar.current?.classList.add('plantBar--light');
    }
  }, [router]);

  return (
    <header className={classnames(styles.Header, 'Header')} ref={headerRef}>
      <Menu routerRoute={router.route} activeLang={activeLang} plantBar={plantBar.current} />
      <div className={styles.brand}>
        <h1>
          <span>i.o.l.e</span>
          <Link href="/" prefetch={false}>
            <a
              onMouseEnter={() => {
                device.desktop && dispatch(setCursorState('hover'));
              }}
              onMouseLeave={() => {
                device.desktop && dispatch(setCursorState('default'));
              }}
              onClick={() => {
                if (router.asPath !== '/') {
                  const mask = debounce(() => {
                    dispatch(setNoiseMask({ state: 'render-out', theme: 'light' }));
                  }, 500);
                  mask();
                }
              }}
            >
              <Logo className={styles.logo} />
            </a>
          </Link>
        </h1>
      </div>
      <div className={styles.plant}>
        <PlantCircle />
      </div>
    </header>
  );
}

export default Header;
