import { memo, useEffect, useRef } from 'react';
import { setAgeGate, setCursorState, setNoiseMask, useAppDispatch } from '@/redux';
import { useCookies } from 'react-cookie';
import { useRouter } from 'next/router';
import LogoFull from '@/assets/svgs/logo-full.svg';
import { gsap } from 'gsap';
import debounce from 'lodash.debounce';
import { device } from '@jam3/detect';
import PlantCircle from '@/assets/svgs/plant-circle.svg';
import Plant from '@/assets/svgs/plant.svg';

function AgeGate() {
  // const ageGate = useAppSelector((state) => state.ageGate);
  const dispatch = useAppDispatch();
  const router = useRouter();
  const TimeLineIn = useRef<GSAPTimeline | null>(null);
  const TimeLineOut = useRef<GSAPTimeline | null>(null);
  const heroImg = useRef<HTMLImageElement | null>(null);
  // @ts-ignore
  const [cookies, setCookie] = useCookies(['isover18']);
  const expiryDate = new Date(Date.now() + 7 * 24 * 60 * 60 * 1000);
  const exitGate = debounce((cookies: boolean) => {
    gsap.to('header.Header', {
      autoAlpha: 1,
      duration: 0.5,
      clearProps: 'all'
    });
    gsap.to('.plantBar', {
      autoAlpha: 1,
      duration: 0.5,
      clearProps: 'all'
    });
    if (router.asPath === '/') {
      dispatch(setNoiseMask({ state: 'out', theme: 'light' }));
    }
    dispatch(setCursorState('default'));
    dispatch(setAgeGate(false));
    if (cookies) {
      setCookie('isover18', true, { path: '/', expires: expiryDate });
    }
  }, 500);

  useEffect(() => {
    gsap.set('header.Header', {
      autoAlpha: 0
    });
    gsap.set('.plantBar', {
      autoAlpha: 0
    });

    if (device.desktop) {
      heroImg.current = document.querySelector('.hero__bg-cover');
    } else {
      heroImg.current = document.querySelector('.hero__bg-mobile');
    }

    if (cookies.isover18) {
      gsap.set('.AgeGate__spinner', {
        autoAlpha: 1
      });
      if (heroImg.current) {
        gsap.to('.AgeGate__spinner', { autoAlpha: 0, duration: 0.2 });
        gsap.to('.AgeGate__bg', { autoAlpha: 0, duration: 0.2 });
        exitGate(false);
        // if (heroImg.current.complete) {
        //   gsap.to('.AgeGate__spinner', { autoAlpha: 0, duration: 0.2 });
        //   gsap.to('.AgeGate__bg', { autoAlpha: 0, duration: 0.2 });
        //   exitGate(false);
        // } else {
        //   heroImg.current.addEventListener('load', () => {
        //     gsap.to('.AgeGate__spinner', { autoAlpha: 0, duration: 0.2 });
        //     gsap.to('.AgeGate__bg', { autoAlpha: 0, duration: 0.2 });
        //     exitGate(false);
        //   });
        // }
      } else {
        gsap.to('.AgeGate__spinner', 0.2, { autoAlpha: 0 });
        gsap.to('.AgeGate__bg', 0.3, { autoAlpha: 0 });
        exitGate(false);
      }
    } else {
      TimeLineIn.current = gsap
        .timeline({ defaults: { duration: 2.5, ease: 'expo' }, delay: 0.5 })
        .set('.AgeGate-textLine-inner', {
          y: '150%',
          rotate: 5
        })
        .set('.AgeGate__image svg', {
          autoAlpha: 0,
          y: '10%',
          scaleY: 1.1
        })
        .to('.AgeGate__logo', {
          autoAlpha: 1,
          y: '0%',
          rotate: 0
        })
        .to(
          '.AgeGate-textLine-inner',
          {
            y: '0%',
            rotate: 0,
            stagger: 0.1,
            duration: 1.2,
            autoAlpha: 1
          },
          0.1
        )
        .to(
          '.AgeGate__button',
          {
            autoAlpha: 1,
            y: '0%',
            rotate: 0
          },
          0.7
        )
        .to(
          '.AgeGate__image svg',
          {
            autoAlpha: 1,
            y: '0%',
            scaleY: 1
          },
          0.5
        );
    }
  }, []);

  const onClickEvent = () => {
    if (TimeLineIn.current && TimeLineIn.current.isActive()) {
      TimeLineIn.current.kill();
    }
    TimeLineOut.current = gsap
      .timeline({
        defaults: { duration: 0.7, ease: 'power2' }
      })
      .to('.AgeGate__logo', {
        autoAlpha: 0,
        y: '-10%'
      })
      .to(
        '.AgeGate-textLine-inner',
        {
          y: '-10%',
          autoAlpha: 0,
          stagger: 0.05
        },
        0
      )
      .to(
        '.AgeGate__bg',
        {
          autoAlpha: 0
        },
        0.1
      )
      .to(
        '.AgeGate__button',
        {
          autoAlpha: 0,
          y: '-10%'
        },
        0.2
      )
      .to(
        '.AgeGate__image svg',
        {
          autoAlpha: 0,
          y: '10%',
          scaleY: 1.1
        },
        0.15
      );
    exitGate(true);
  };

  return (
    <div className="AgeGate">
      <div className="AgeGate__bg"></div>
      <div className="AgeGate__logo">
        <LogoFull />
      </div>
      <div className="AgeGate__content">
        <div className="AgeGate__title">
          <div className="AgeGate-textLine">
            <div className="AgeGate-textLine-inner">
              {router.locale === 'en' ? 'Are you of legal' : 'Sei maggiorenne ?'}
            </div>
          </div>
          {router.locale === 'en' && (
            <div className="AgeGate-textLine">
              <div className="AgeGate-textLine-inner">drinking age ?</div>
            </div>
          )}{' '}
        </div>
        <div className="AgeGate__description">
          <div className="AgeGate-textLine">
            <div className="AgeGate-textLine-inner">
              {router.locale === 'en'
                ? 'To experience i.o.l.e you must be of the minimum drinking'
                : "Per accedere al sito i.o.l.e devi avere l'età minima per"}
            </div>
          </div>
          <div className="AgeGate-textLine">
            <div className="AgeGate-textLine-inner">
              {router.locale === 'en'
                ? 'age established by the law of the country in which you reside'
                : 'bere alcolici stabilita dalla legge della nazione in cui risiedi.'}
            </div>
          </div>
        </div>
        <button
          className="AgeGate__button"
          onMouseEnter={() => {
            device.desktop && dispatch(setCursorState('hover'));
          }}
          onMouseLeave={() => {
            device.desktop && dispatch(setCursorState('default'));
          }}
          onClick={onClickEvent}
        >
          <span>{router.locale === 'en' ? 'Yes' : 'Si'}</span>
        </button>
      </div>
      <div className="AgeGate__image">
        <div className="AgeGate__image-wrap">
          <Plant />
        </div>
      </div>
      <div className="AgeGate__spinner">
        <PlantCircle />
      </div>
      <style jsx>{`
        @import '../../styles/shared';
        .AgeGate {
          background-color: transparent;
          @include z-index(agegate);
          position: fixed;
          @include fill;
          height: 100vh;
          display: flex;
          flex-direction: column;
          align-items: center;
          .AgeGate__bg {
            @include pos100(absolute);
            background-color: $white;
          }
          .AgeGate__logo {
            width: px(100);
            opacity: 0;
            visibility: hidden;
            padding-top: 10vmax;
            will-change: transform;
            transform: translateY(10%) rotate(2deg);
            @include media('>=medium') {
              width: px(120);
            }
            @include media('>=large') {
              padding-top: 4vmax;
              width: px(130);
            }
            :global(svg) {
              width: 100%;
            }
          }
          .AgeGate__content {
            flex-grow: 1;
            flex-direction: column;
            @include flex-center;
            text-align: center;
            padding: 0 5vw 50vw;
            @include media('>=medium') {
              padding: 0 15vw 20vw;
            }
            @include media('>=large') {
              padding: 0 28vw 10vw;
            }
            @include media('>=slarge') {
              padding: 0 30vw 10vw;
            }
            @include media('>=mlarge') {
              padding: 0 32vw 10vw;
            }
            .AgeGate-textLine {
              overflow: hidden;
              display: block;
            }
            .AgeGate-textLine-inner {
              display: block;
              width: 100%;
              opacity: 0;
              visibility: hidden;
              position: relative;
              will-change: transform;
              transform: translateY(150%) rotate(5deg);
            }
            .AgeGate__title {
              position: relative;
              display: flex;
              flex-direction: column;
              line-height: 1;
              margin-bottom: var(--space-xxxs);
              .AgeGate-textLine-inner {
                font-family: var(--font-secondary);
                font-weight: 800;
                line-height: 1.3;
                font-size: 2rem;
                font-size: clamp(1.4rem, 0.5325rem + 3.8554vw, 3rem);
                @include media('>=large') {
                  font-size: 3rem;
                  font-size: clamp(2.5rem, 1.1939rem + 2.0408vw, 4rem);
                }
              }
            }
            .AgeGate__description {
              position: relative;
              display: flex;
              flex-direction: column;
              margin-bottom: var(--space-sm);
              .AgeGate-textLine-inner {
                line-height: 1.6;
                font-size: 1.2rem;
                font-size: clamp(1rem, 0.7289rem + 1.2048vw, 1.5rem);
                @include media('>=large') {
                  font-size: 1.4rem;
                  font-size: clamp(1.25rem, 0.7711rem + 0.7483vw, 1.8rem);
                }
              }
            }
            .AgeGate__button {
              padding: px(5) px(35);
              color: $black;
              letter-spacing: 4px;
              text-transform: uppercase;
              border: 2px solid $red;
              transition: background-color 0.3s ease;
              will-change: transform;
              opacity: 0;
              visibility: hidden;
              transform: translateY(20%) rotate(1deg);
              @include media('>=large') {
                font-size: 1.4rem;
                font-size: clamp(1.2rem, 0.6776rem + 0.8163vw, 1.8rem);
              }

              &:hover {
                color: $white;
                background-color: $red;
              }
            }
          }
          .AgeGate__image {
            position: absolute;
            top: 92vh;
            opacity: 0.7;
            @include center(x);
            width: px(160);
            @include media('>=medium') {
              width: px(240);
            }
            @include media('>=large') {
              top: 90vh;
              width: px(280);
              width: clamp(px(180), 30%, px(320));
            }
            .AgeGate__image-wrap {
              transform: scaleX(-1) translateY(-45%);
            }
            :global(svg) {
              width: 100%;
              height: auto;
              will-change: transform;
              opacity: 0;
              transform: translateY(20%) scaleY(1.1);
              :global(path) {
                fill: $dark;
              }
            }
          }
          .AgeGate__spinner {
            position: absolute;
            @include center();
            width: px(100);
            height: px(100);
            opacity: 0;
            visibility: hidden;
            :global(svg) {
              width: 100%;
              height: auto;
              animation: icon-spin 10s infinite linear;
              transform-origin: center;
            }
          }
        }
      `}</style>
    </div>
  );
}

export default memo(AgeGate);
