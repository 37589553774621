import { Scroll } from 'locomotive-scroll';
import { createContext, DependencyList, MutableRefObject, useEffect, useRef, useState } from 'react';
import { useDebounce } from 'use-debounce';
import debounce from 'lodash.debounce';
import useResizeObserver from 'use-resize-observer';
import { device } from '@jam3/detect';

export interface LocomotiveScrollContextValue {
  scroll: Scroll | null;
  isReady: boolean;
}

export const LocomotiveScrollContext = createContext<LocomotiveScrollContextValue>({
  scroll: null,
  isReady: false
});

export interface LocomotiveScrollProviderProps {
  siteWrapperRef: MutableRefObject<HTMLDivElement | null>;
  scrollContainerRef: MutableRefObject<HTMLDivElement | null>;
  watch: DependencyList | undefined;
  location: string;
}

export function LocomotiveScrollProvider({
  children,
  siteWrapperRef,
  scrollContainerRef,
  watch,
  location
}: WithChildren<LocomotiveScrollProviderProps>) {
  const { height: containerHeight } = useResizeObserver<HTMLDivElement>({ ref: siteWrapperRef });
  const [isReady, setIsReady] = useState(false);
  const LocomotiveScrollRef = useRef<Scroll | null>(null);
  const [height] = useDebounce(containerHeight, 100);
  const homeCTA = useRef<HTMLDivElement | null>(null);
  const initCTAListener = debounce(() => {
    homeCTA.current = document.querySelector('.hero__cta-icon');
    homeCTA.current?.addEventListener('click', scrollToHomeCTA);
  }, 800);

  const scrollEventsGeneric = (loc: string) => {
    return function generic({ scroll, limit }: any) {
      const percentage = loc === 'horizontal' ? (scroll.x / limit.x) * 100 : (scroll.y / limit.y) * 100;
      const plantBar = document.querySelector('.plantBar__inner') as HTMLSpanElement;
      (plantBar as HTMLSpanElement).style.height = `${percentage}%`;
    };
  };
  const scrollCallHomeDesktop = () => {
    return function home(data: string | string[], state: string) {
      const header = document.querySelector('header.Header') as HTMLElement;
      const bar = document.querySelector('.plantBar') as HTMLElement;
      if (state === 'enter') {
        if (data === 'tonico' || data === 'territorio') {
          header.classList.add(`Header--dark`);
          bar.classList.add(`plantBar--dark`);

          header.classList.remove('Header--light');
          bar.classList.remove('plantBar--light');
        }
        if (data === 'ricordo') {
          header.classList.add(`Header--light`);
          bar.classList.add(`plantBar--light`);
        }
      } else {
        if (data === 'tonico') {
          header.classList.remove(`Header--dark`);
          bar.classList.remove(`plantBar--dark`);
        }
        if (data === 'territorio') {
          header.classList.remove(`Header--dark`);
          bar.classList.remove(`plantBar--dark`);

          header.classList.add('Header--light');
          bar.classList.add('plantBar--light');
        }
      }
    };
  };
  const scrollCallHomeMobile = () => {
    return function home(data: string | string[], state: string) {
      const header = document.querySelector('header.Header') as HTMLElement;
      if (state === 'enter') {
        if (data === 'tonico' || data === 'territorio') {
          header.classList.add(`Header--dark`);
          header.classList.remove('Header--light');
        }
        if (data === 'ricordo') {
          header.classList.add(`Header--light`);
        }
      } else {
        if (data === 'tonico') {
          header.classList.remove(`Header--dark`);
        }
        if (data === 'territorio') {
          header.classList.remove(`Header--dark`);
          header.classList.add('Header--light');
        }
      }
    };
  };

  const initSmooth = () => {
    (async () => {
      try {
        const LocomotiveScroll = (await import('locomotive-scroll')).default;

        if (!scrollContainerRef.current) {
          console.warn(
            `react-locomotive-scroll: [data-scroll-container] dataset was not found. You likely forgot to add it which will prevent Locomotive Scroll to work.`
          );
        }

        LocomotiveScrollRef.current = new LocomotiveScroll({
          el: scrollContainerRef.current ?? undefined,
          direction: location === '/' ? 'horizontal' : 'vertical',
          smooth: true,
          lerp: 0.12
        });

        if (device.desktop) {
          LocomotiveScrollRef.current.on('scroll', scrollEventsGeneric(location === '/' ? 'horizontal' : 'vertical'));
          if (location === '/') {
            // LocomotiveScrollRef.current.stop();
            LocomotiveScrollRef.current.on('call', scrollCallHomeDesktop());
          }
        } else {
          if (location === '/') {
            LocomotiveScrollRef.current.on('call', scrollCallHomeMobile());
          }
        }

        setIsReady(true); // Re-render the context
      } catch (error) {
        throw Error(`react-locomotive-scroll: ${error}`);
      }
    })();
  };

  if (!watch) {
    console.warn(
      'react-locomotive-scroll: you did not add any props to watch. Scroll may have weird behaviours if the instance is not updated when the route changes'
    );
  }

  useEffect(() => {
    initSmooth();
    if (location === '/') {
      initCTAListener();
    }
    return () => {
      LocomotiveScrollRef.current?.destroy();
      setIsReady(false);
    };
  }, []);

  useEffect(
    () => {
      if (!LocomotiveScrollRef.current) {
        return;
      }

      LocomotiveScrollRef.current.update();
    },
    watch ? [...watch, height] : [height]
  );

  useEffect(() => {
    if (!LocomotiveScrollRef.current || !location) {
      return;
    }

    LocomotiveScrollRef.current.destroy();

    const initSmoothDebounced = debounce(() => {
      initSmooth();
    }, 300);
    initSmoothDebounced();

    if (location === '/') {
      initCTAListener();
    }
  }, [location]);

  const scrollToHomeCTA = () => {
    const tonicoSection = document.querySelector('.tonico');
    LocomotiveScrollRef.current?.start();
    LocomotiveScrollRef.current?.scrollTo(tonicoSection as HTMLElement, { duration: 2000 });
  };

  return (
    <LocomotiveScrollContext.Provider value={{ scroll: LocomotiveScrollRef.current, isReady }}>
      {children}
    </LocomotiveScrollContext.Provider>
  );
}

LocomotiveScrollContext.displayName = 'LocomotiveScrollContext';
LocomotiveScrollProvider.displayName = 'LocomotiveScrollProvider';
